<template>
  <b-card>
    <pocu-header></pocu-header>
    <div v-if="loadingDone && haveTableData">
      <div>
        <b-form-group>
          <b-form-checkbox-group
            id="filtruParteneri"
            v-model="filtruParteneri"
            :options="parteneri"
            name="filtru-parteneri"
            buttons
            size="sm"
            button-variant="outline-primary"
          />
        </b-form-group>
      </div>
      <div>
        <b-row>
          <b-col cols="6">
            <b-row id="additionalColumnFilters">
              <b-col cols="4">
                <b-form-group>
                  <v-select
                    v-model="filtruActivitate"
                    label="title"
                    :options="filtruActivitateOptiuni"
                    placeholder="Activitate"
                    v-bind:searchable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group>
                  <v-select
                    v-model="filtruSubactivitate"
                    label="title"
                      :options="filtruSubactivitateOptiuni"
                      placeholder="Subactivitate"
                      v-bind:searchable="false"
                    />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group>
                  <v-select
                  v-model="filtruCategorie"
                      label="title"
                      :options="filtruCategorieOptiuni"
                      placeholder="Categorie"
                      v-bind:searchable="false"
                    />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <!-- search -->
             <b-form-group>
               <b-input-group size="md">
                 <b-form-input
                   id="filterInput"
                   v-model="searchString"
                   type="search"
                   placeholder="Caută..."
                 />
                 <b-input-group-append>
                   <b-button
                     :disabled="!searchString"
                     @click="searchString = ''"
                   >
                     Șterge
                   </b-button>
                 </b-input-group-append>
               </b-input-group>
             </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div class="d-flex justify-content-between flex-wrap pt-0" style="margin-bottom:1rem;">

        <!-- page length -->
        <b-form-group
          label="Per Pagină"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1 per_page_label"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPageSelect"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>

      <b-table
        striped
        hover
        responsive
        sticky-header="500px"
        class="position-relative linii-bugetare"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        :filter-function="filterFunc"
        @filtered="onFiltered"
        :sort-compare="sortCompareFunc"
        @row-clicked="rowClicked"
      >
        <template #cell(partener)="data">
          <span :title="data.value.nume" >{{data.value.text}}</span>
        </template>
        <template #cell(activitate)="data">
          <span :title="data.value.nume">{{data.value.text}}</span>
        </template>
        <template #cell(subactivitate)="data">
          <span :title="data.value.nume">{{data.value.text}}</span>
        </template>
        <template #cell(categorie)="data">
          <span :title="data.value.nume">{{data.value.text}}</span>
        </template>
        <template #cell(cheltuiala)="data">
          <span :title="data.value.nume">{{data.value.text}}</span>
        </template>
        <template #cell(produs)="data">
          <span :title="data.value.nume">{{data.value.text}}</span>
        </template>
        <!--<template #cell(cheltuiala_totala)="data">
          <span v-html="data.value.valoare" style="float:left;font-weight:700"></span><span v-html="data.value.tva" style="float:right"></span>
        </template>-->
         <template #cell(cheltuiala_totala)="data">
          <span style="font-weight:700;white-space: nowrap;">{{ data.value}}</span>
        </template>
        <template #cell(contributie_proprie)="data">
          <span style="white-space: nowrap;">{{data.value}}</span>
        </template>
        <template #row-details="row">
          <b-card>
            <div>
              <span class="row_detail_line"><strong>Linie</strong> : {{row.item.index}}</span>
              <span class="row_detail_line"><strong>Partener</strong> : {{row.item.partener.nume}}</span>
              <span class="row_detail_line"><strong>Linie Bugetară</strong> : {{row.item.id_linie_bugetara}}</span>
              <span class="row_detail_line"><strong>Activitate</strong> : {{row.item.activitate.nume}}</span>
              <span class="row_detail_line"><strong>Subactivitate</strong> : {{row.item.subactivitate.nume}}</span>
              <span class="row_detail_line"><strong>Categorie</strong> : {{row.item.categorie.nume}}</span>
              <span class="row_detail_line"><strong>Cheltuială</strong> : {{row.item.cheltuiala.nume}}</span>
              <span class="row_detail_line"><strong>Produs/Serviciu</strong> : {{row.item.produs.nume}}</span>
              <span class="row_detail_line"><strong>Cheltuială Totală</strong> : {{row.item.cheltuiala_totala}}</span>
              <span class="row_detail_line"><strong>Contribuție Proprie</strong> : {{row.item.contributie_proprie}}</span>
              <span class="row_detail_line"><strong>Tip Cheltuială</strong> : {{row.item.tip_cheltuiala}}</span>
            </div>
            <b-button size="sm" pill @click="row.toggleDetails">Ascunde Detaliile</b-button>
          </b-card>
        </template>
      </b-table>

      <div class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Pagină"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1 per_page_label"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPageSelect"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
    <div v-else>
      <div id="no_project" v-if="!currentProject">
        <p>Nu ai nici un proiect. Poți adăuga un proiect nou folosind butonul de mai sus.</p>
      </div>
      <div v-else>
        <div id="no_content" v-if="loadingDone">
          <a class="go_to_upload" @click="goToUpload()">Te rugăm să încarci documentele necesare</a>
        </div>
        <div id="loading_animation" v-else>
          <b-spinner
            variant="primary"
          /> Se încarcă
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import axios from '@axios'
import {
 BRow, BCol, BCard, BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BFormCheckboxGroup, BIconNutFill, BSpinner, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import PocuHeader from './parts/PocuHeader.vue'
import {mapState} from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormCheckboxGroup,
    vSelect,
    PocuHeader,
    BSpinner,
  },
  data() {
    return {
      loadingDone: false,
      rawData : [],
      perPage: 50,
      perPageSelect: 50,
      parteneri: [],
      filtruParteneri: [],
      filtruActivitateOptiuni: [],
      filtruActivitate: null,
      filtruSubactivitateOptiuni: [],
      filtruSubactivitate: null,
      filtruCategorieOptiuni: [],
      filtruCategorie: null,
      pageOptions: [5, 10, 20, 50, 100, 'Toate'],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'index',
      sortDesc: false,
      sortDirection: 'asc',
      searchString: '',
      searchId: null,
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'Linie', sortable:true, thStyle:'width:1%;text-align:center;white-space:nowrap;vertical-align:middle;border-top:0;' },
        { key: 'partener', label: 'Companie', sortable: true, thStyle:'width:10%;text-align:center;white-space:nowrap;vertical-align:middle;border-top:0;' },
        { key: 'activitate', label: 'Activitate', sortable: true, thStyle:'width:10%;text-align:center;white-space:nowrap;vertical-align:middle;border-top:0;' },
        { key: 'subactivitate', label: 'Subactivitate', sortable: true, thStyle:'width:10%;text-align:center;white-space:nowrap;vertical-align:middle;border-top:0;' },
        { key: 'categorie', label: 'Categorie', sortable: true, thStyle:'width:10%;text-align:center;white-space:nowrap;vertical-align:middle;border-top:0;' },
        { key: 'cheltuiala', label: 'Cheltuială', sortable: true, thStyle:'width:10%;text-align:centerwhite-space:nowrap;vertical-align:middle;border-top:0;' },
        { key: 'produs', label: 'Produs/Serviciu', sortable: true, thStyle:'width:15%;text-align:center;white-space:nowrap;vertical-align:middle;border-top:0;' },
        { key: 'cheltuiala_totala', label: 'Cheltuială Totală (inclusiv TVA)', sortable: true, escape: false, thStyle:'width:8%;white-space:break-spaces;text-align:center;vertical-align:middle;border-top:0;' },
        { key: 'contributie_proprie', label: 'Contribuție Proprie (inclusiv TVA)', sortable: true, thStyle:'width:8%;white-space:break-spaces;text-align:center;vertical-align:middle;border-top:0;' },
        { key: 'tip_cheltuiala', label: 'Tip Cheltuială', sortable: true, thStyle:'width:8%;text-align:center;white-space:break-spaces;vertical-align:middle;border-top:0;' }
      ],
      /* eslint-disable global-require */
      items: [
        
      ],
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    haveTableData(){
      return this.parteneri.length > 0
    },
    ...mapState('pocu',{
      currentProjectId : 'project_id',
      allProjects: 'projects'
    }),
    currentProject(){
      return this.allProjects[this.currentProjectId]
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  watch: {
    perPageSelect(newVal,oldVal){
      if (newVal === 'Toate'){
        this.perPage = this.rawData.length
      } else {
        this.perPage = newVal
      }
    },
    currentProjectId: {
      handler: function(){
        this.getLiniiBugetare()
      },
      deep: true,
    },
    filtruParteneri : {
      handler: function(){
        this.filter = this.filter.length == 0 ? '!ignore' : this.filter
      },
      deep: true,
    },
    filtruActivitate: {
      handler: function(){
        this.filter = this.filter.length == 0 ? '!ignore' : this.filter
      },
      deep: true,
    },
    filtruSubactivitate: {
      handler: function(){
        this.filter = this.filter.length == 0 ? '!ignore' : this.filter
      },
      deep: true,
    },
    filtruCategorie: {
      handler: function(){
        this.filter = this.filter.length == 0 ? '!ignore' : this.filter
      },
      deep: true,
    },
    searchString: {
      handler: function(){
        const $this = this
        let searchId = Math.random().toString(36).substring(7);
        $this.searchId = searchId
        setTimeout(function(){
          if ($this.searchId == searchId){
            $this.filter = $this.searchString
          }
        },300)
      },
      deep: true,
    },
  },
  methods: {
    rowClicked(item,index){
      this.$set(item, '_showDetails', !item._showDetails)
    },
    maybeShowDecimals(value){
      if(Math.round(value) !== +value) {
        return +value
      }
      return Math.round(value)
    },
    sortCompareFunc(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale){
      const a = aRow[key] // or use Lodash `_.get()`
      const b = bRow[key]
      if (
        (typeof a === 'number' && typeof b === 'number') ||
        (a instanceof Date && b instanceof Date)
      ) {
        // If both compared fields are native numbers or both are native dates
        return a < b ? -1 : a > b ? 1 : 0
      } else {
        // Otherwise stringify the field data and use String.prototype.localeCompare
        return this.toString(a).localeCompare(toString(b), compareLocale, compareOptions)
      }
    },
    toString(value){
      if (value === null || typeof value === 'undefined') {
        return ''
      } else if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map(key => toString(value[key]))
          .join(' ')
      } else {
        return String(value)
      }
    },
    goToUpload(){
      this.$router.push({name:'pocu-documentele-mele'})
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filterFunc(row,filter){
      if (this.filtruParteneri.length > 0){
        if (!(this.filtruParteneri.includes(row.partener.nume))){
          return false;
        }
      }
      if (this.filtruActivitate && this.filtruActivitate.title){
        if (this.filtruActivitate.title != row.activitate.nume){
          return false;
        }
      }
      if (this.filtruSubactivitate && this.filtruSubactivitate.title){
        if (this.filtruSubactivitate.title != row.subactivitate.nume){
          return false;
        }
      }
      if (this.filtruCategorie && this.filtruCategorie.title){
        if (this.filtruCategorie.title != row.categorie.nume){
          return false;
        }
      }
      if (filter == '!ignore'){
        return true;
      }
      if (row.produs.text.toLowerCase().includes(filter.toLowerCase())){
        return true;
      }
      return false;
      // console.log(row)
      // console.log(filter)
    },
    getLiniiBugetare(){
      this.loadingDone = false
      axios
      .get('/pocu/getLiniiBugetare',{
        params: {
          id_proiect: this.currentProjectId
        }
      })
      .then(response => {
        if (response.data.success){
          this.parteneri = []
          let parteneriGasiti = []
          let activitatiGasite = []
          let subactivitatiGasite = []
          let categoriiGasite = []
          this.items =  response.data.items.map(item => {
            if (!parteneriGasiti.includes(item.nume_partener)){
              parteneriGasiti.push(item.nume_partener)
              this.parteneri.push({
                text : item.nume_partener.substring(0,30) + (item.nume_partener.length > 30 ? "..." : ""),
                value : item.nume_partener,
              })
            }
            if (!activitatiGasite.includes(item.activitate)){
              activitatiGasite.push(item.activitate)
              this.filtruActivitateOptiuni.push({
                title: item.activitate
              })
            }
            if (!subactivitatiGasite.includes(item.subactivitate)){
              subactivitatiGasite.push(item.subactivitate)
              this.filtruSubactivitateOptiuni.push({
                title: item.subactivitate
              })
            }
            if (!categoriiGasite.includes(item.categorie)){
              categoriiGasite.push(item.categorie)
              this.filtruCategorieOptiuni.push({
                title: item.categorie
              })
            }
            return {
                index : parseInt(item.excel_line),
                partener: {
                  text : item.nume_partener.substring(0,30) + (item.nume_partener.length > 30 ? "..." : ""),
                  // text: item.nume_partener,
                  nume: item.nume_partener,
                },
                id_linie_bugetara: item.id_linie_bugetara,
                activitate: {
                  text: item.activitate.substring(0,30) + (item.activitate.length > 30 ? "..." : ""),
                  nume: item.activitate,
                },
                subactivitate: {
                  text: item.subactivitate.substring(0,30) + (item.subactivitate.length > 30 ? "..." : ""),
                  // text: item.subactivitate,
                  nume: item.subactivitate,
                },
                categorie: {
                  text: item.categorie.substring(0,30) + (item.categorie.length > 30 ? "..." : ""),
                  // text: item.categorie,
                  nume: item.categorie,
                },
                cheltuiala: {
                  text: item.cheltuiala.substring(0,30) + (item.cheltuiala.length > 30 ? "..." : ""),
                  nume: item.cheltuiala,
                }, 
                produs: {
                  text: item.produs.substring(0,30) + (item.produs.length > 30 ? "..." : ""),
                  nume: item.produs,
                },
                // cheltuiala_totala:{
                //   valoare: this.maybeShowDecimals(parseFloat(item.total_cheltuieli).toFixed(2)),
                //   tva: this.maybeShowDecimals(parseFloat(item.total_cheltuieli_cu_tva - item.total_cheltuieli).toFixed(2)),
                // },
                cheltuiala_totala: this.maybeShowDecimals(parseFloat(item.total_cheltuieli_cu_tva).toFixed(2)),
                contributie_proprie: this.maybeShowDecimals(parseFloat(item.contributie_proprie).toFixed(2)),
                tip_cheltuiala: item.tip_cheltuiala
            }
          })
          this.filtruActivitateOptiuni.sort((a,b)=>{
            return a.title.localeCompare(b.title)
          })
          this.filtruSubactivitateOptiuni.sort((a,b)=>{
            return a.title.localeCompare(b.title)
          })
          this.filtruCategorieOptiuni.sort((a,b)=>{
            return a.title.localeCompare(b.title)
          })
          this.parteneri.sort()
          this.totalRows = this.items.length
          this.rawData = response.data.items
          this.rawDataJson = JSON.stringify(this.rawData)
        }
        this.loadingDone = true
        // this.rows = response.data
      })
    },
  },
  created(){
    if (this.items.length == 0){
      this.getLiniiBugetare()
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
th,td{
  white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
}

.linii-bugetare{
  font-size:12px;
}
.linii-bugetare td{
  /* padding: 0.5rem 0.5rem; */
  /* display:inline-block; */
  white-space:nowrap;
  overflow-x:hidden;
}
#filtruParteneri .btn-outline-primary.active{
  background-color:#01cfb0 !important;
  color:#fff;
}
#additionalColumnFilters ul{
  display:block;
  overflow-x:hidden;
  min-width:500px;
}
#additionalColumnFilters ul li{
  width:100%;
  display:block;
  white-space: normal;
}
#additionalColumnFilters .vs__selected{
  white-space:nowrap;
  overflow-x:hidden;
  text-overflow:ellipsis;
}
#additionalColumnFilters .vs__selected-options, #additionalColumnFilters .vs__selected-options .vs__selected{
  width:100%;
}
#additionalColumnFilters .vs__selected-options .vs__selected{
  appearance: none;
  line-height: 1.8;
  font-size: 1em;
  outline: none;
  width: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  z-index: 1;
}
#additionalColumnFilters .vs__selected + .vs__search{
  display:none;
}
#loading_animation{
  text-align:center;
}
#no_content{
  text-align:center;
}
#no_project{
  text-align:center;
}
.go_to_upload{
  color:#43eed5;
  text-decoration:underline;
}
.row_detail_line{
  display:block;
}
.per_page_label label{
  padding:0.6rem;
}
</style>